<template>
  <OverlayPanel
    ref="shippingPricePanelRef"
    appendTo="body"
    :dismissable="true"
    id="shipping-price_overlay_panel"
    :breakpoints="{ '960px': '75vw', '440px': '100vw', '90000px': '300px' }"
    class="shipping-price-panel"
    @keydown.esc.stop="hideOverlayPanel"
  >
    <ShippingPrice
      ref="shippingPriceRef"
      v-model:shippingPrice="shippingPrice"
      v-model:agreedFreight="agreedFreight"
      :orderStatus="props.orderStatus"
      :currencyIso="props.currencyIso"
      :freightMethod="props.freightMethod"
      @shippingCalculationDialogVisible="emit('shippingCalculationDialogVisible', $event)"
      @closeShippingPricePanel="closeShippingPricePanel"
    />
  </OverlayPanel>
</template>

<script setup lang="ts">
import { ref } from "vue";
import ShippingPrice from "./ShippingPrice.vue";
import { OrderStatus } from "@/models/order/OrderStatus";
import { OrderFreightMethod } from "@/models/order/OrderFreightMethod";

const props = defineProps<{
  currencyIso: string;
  orderStatus?: OrderStatus;
  freightMethod: OrderFreightMethod;
}>();

const emit = defineEmits<{
  (e: "shippingCalculationDialogVisible", value: boolean): void;
  (e: "closeShippingPricePanel"): void;
}>();

const shippingPrice = defineModel<number>("shippingPrice", { required: true });
const agreedFreight = defineModel<boolean>("agreedFreight", { required: true });

const shippingPricePanelRef = ref();
const toggle = (event: Event) => shippingPricePanelRef.value.toggle(event);

const show = (event: Event) => {
  shippingPricePanelRef.value.show(event);
};

const hideOverlayPanel = () => {
  shippingPricePanelRef.value.hide();
};

const closeShippingPricePanel = () => {
  hideOverlayPanel();
  emit("closeShippingPricePanel");
};

defineExpose({ toggle: toggle, show: show });
</script>

<style scoped lang="scss">
.shipping-price-panel .p-overlaypanel-content {
  height: auto;
}
</style>
