<template>
  <div class="c-footer-containar" :class="props.class">
    <div class="c-card">
      <div class="c-order-footer">
        <div class="flex justify-content-between flex-wrap row-gap-2 column-gap-2">
          <div class="c-order-total-column">
            <label for="orderline">{{ t("order.footer.orderline") }}</label>
            <div id="orderline" data-testid="order-total-orderline">{{ orderlines }}</div>
          </div>

          <div class="c-order-total-column">
            <label for="discount">{{ t("order.footer.discount") }}</label>
            <div id="discount">
              <span data-testid="order-total-discount-percent">
                {{ n(props.calculatedOrderTotal.totalDiscountPercentage, "decimal") }}</span
              >
              %&nbsp;/
              <span data-testid="order-total-discount">
                {{ n(props.calculatedOrderTotal.totalDiscountAmount, "decimal") }}
              </span>
            </div>
          </div>

          <div class="c-order-total-column">
            <label for="margin">{{ t("order.footer.margin") }}</label>
            <span id="margin">
              <span data-testid="order-total-margin-percent">
                {{ n(props.calculatedOrderTotal.totalContributionMarginPercentage, "decimal") }}</span
              >
              %&nbsp;/
              <span data-testid="order-total-margin">
                {{ n(props.calculatedOrderTotal.totalContributionMargin, "decimal") }}
              </span>
            </span>
          </div>

          <div class="c-order-total-column">
            <label>{{ t("order.footer.sum-ex-vat") }}</label>
            <span data-testid="order-total-sum-ex-vat">{{ n(calculatedOrderTotal.sumTotalLines, "decimal") }}</span>
          </div>

          <div class="c-order-total-column">
            <label>{{ t("order.footer.vat") }}</label>
            <span data-testid="order-total-vat">{{ n(calculatedOrderTotal.sumTotalLinesVatAmount, "decimal") }}</span>
          </div>

          <div class="c-order-total-column">
            <label>{{ t("order.footer.sum-inc-vat") }}</label>
            <span data-testid="order-total-sum-inc-vat">{{
              n(calculatedOrderTotal.sumTotalLinesIncVat, "decimal")
            }}</span>
          </div>

          <div class="c-order-total-column">
            <label for="purchase-agreed-freight">{{ t("order.footer.freight-inc-vat") }}</label>
            <div data-testid="order-agreed-freight">{{ n(freightPriceComputed, "decimal") }}</div>
          </div>

          <div class="flex flex-column w-15rem h-4rem font-bold flex align-items-center justify-content-center">
            <label>{{ t("order.footer.total") }}</label>
            <span class="c-order-total-summary" data-testid="order-total-summary">
              {{ n(calculatedOrderTotal.totalSumIncVat, "decimal") }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CalculatedOrderTotal } from "@/models/order/CalculatedOrderTotal";
import { OrderStatus } from "@/models/order/OrderStatus";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  calculatedOrderTotal: CalculatedOrderTotal;
  orderStatus?: OrderStatus;
  orderlines?: number;
  class?: Array<string> | string;
}>();

const emit = defineEmits<{
  (e: "reCalculateOrderTotal"): void;
}>();

const shippingPrice = defineModel<number>("shippingPrice", { required: true });

const { t, n } = useI18n();

const freightPriceComputed = computed({
  get() {
    return shippingPrice.value ?? 0;
  },
  set(value: number) {
    shippingPrice.value = value;
    emit("reCalculateOrderTotal");
  },
});
</script>

<style scoped lang="scss">
.c-footer-containar {
  position: sticky;
  padding: 0rem 1rem 1rem 1.2rem;
  bottom: 0;
  width: 100%;
  background-color: var(--surface-a);
  z-index: 1;
  background-color: var(--portal-main-bg-color);

  &.c-show-sidebar {
    width: calc(100% - 27rem);
    padding-right: 0.2rem;
  }

  .c-card {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-top: none;
    margin-top: 0;
    padding-top: 0.7rem;
  }
}

.c-order-footer {
  background: var(--footer-order-color-bg);
  border-radius: 4px;
  width: 100%;
}
.c-order-footer label {
  color: var(--footer-order-color-test);
  font-weight: normal;
}

.c-order-total-summary {
  font-size: 1.3rem;
}

.c-order-total-column {
  display: flex;
  flex-direction: column;
  height: 4rem;
  width: 10rem;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}
</style>
