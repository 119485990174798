import { OrderLine } from "./OrderLine";
import { NIL as emptyUuid } from "uuid";

export class OrderPartialUpdate {
  id = emptyUuid;
  orderLines: OrderLine[] = [];

  constructor(id: string, orderLines: OrderLine[]) {
    this.id = id;
    this.orderLines = orderLines;
  }
}
