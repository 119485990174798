<template>
  <FloatLabelDropdownPanel
    id="customer-search-input"
    :selectedItemName="$props.customer?.customerName ?? ''"
    :label="t('order.customer.label')"
    :selectLabel="t('order.customer.select')"
    @toggleDropdownPanel="showCustomerPanel"
    dataTestId="customer-search-input"
    class="c-customer-dropdown"
    :class="{ 'p-invalid': val.customer.$error, 'p-disabled': !allowEdit }"
    :setFocus="focusSearchInputComputed"
    :disabled="!allowEdit"
  />

  <small class="ml-4 p-error" v-if="val.$error" data-testid="customer-search-input-error">
    {{ val.$errors[0].$message }}
  </small>

  <CustomerPanel ref="customerPanelRef" :customer="props.customer" @selectedCustomerId="selectedCustomerId" />
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import CustomerPanel from "./CustomerPanel.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { OrderCustomer } from "@/models/order/OrderCustomer";
import { OrderStatus } from "@/models/order/OrderStatus";
import { OrderLine } from "@/models/order/OrderLine";

const { t } = useI18n();

const props = defineProps<{
  focusSearchInput: boolean;
  customer: OrderCustomer;
  orderStatus?: OrderStatus;
  orderLines: OrderLine[];
}>();

const emit = defineEmits<{
  (e: "update:focusSearchInput", value: boolean): void;
  (e: "selectedCustomerId", value: string): void;
}>();

const focusSearchInputComputed = computed<boolean>({
  get: () => props.focusSearchInput,
  set: (value) => emit("update:focusSearchInput", value),
});

const selectedCustomerId = (id: string) => {
  focusSearchInputComputed.value = false;
  emit("selectedCustomerId", id);
};

const customerPanelRef = ref();
const showCustomerPanel = (event: Event) => {
  const key = (event as KeyboardEvent).key;
  if (key === "Shift" || key === "Escape" || key === "Tab") {
    return;
  }

  customerPanelRef.value.toggle(event);
};

const rules = {
  customer: {
    customerName: {
      required,
    },
  },
};

const val = useVuelidate(rules, props);

const allowEdit = computed<boolean>(() => {
  return !props.orderStatus || props.orderStatus === OrderStatus.Open;
});
</script>
<style scoped lang="scss">
.c-customer-dropdown {
  width: 100%;
}
</style>
