<template>
  <div>
    <PrimeButton
      text
      type="button"
      icon="pi pi-ellipsis-h"
      @click="toggleAddressMenu"
      class="c-address-menu mr-2"
      aria-haspopup="true"
      aria-controls="overlay_address_menu"
      tabindex="-1"
      data-testid="info-menu-button"
    />
    <PrimeMenu ref="addressMenu" id="overlay_address_menu" :model="addressMenuOptions" :popup="true">
      <template #item="{ item, props }">
        <a
          class="flex align-items-center"
          v-bind="props.action"
          :data-testid="item.dataTestId"
          :disabled="item.disabled"
          :class="item.class"
        >
          <span :class="item.icon" />
          <span class="ml-2">{{ item.label }}</span>
          <span v-if="item.shortcut" class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{{
            item.shortcut
          }}</span>
        </a>
      </template></PrimeMenu
    >

    <TabView class="c-tabview" :tabindex="-1" data-testid="information-tab">
      <TabPanel :header="t(`order.info-tabs.first-tab`)">
        <div>
          <table>
            <tr>
              <td class="w-full text-sm" v-if="customer.customerName">
                <span data-testid="customer-name">{{ customer.customerName }}</span> /
                <span data-testid="customer-number">{{ customer.customerNumber }}</span>
              </td>
              <td class="w-full text-sm" v-else>
                <span>
                  {{ t(`order.customer.no-customer`) }}
                </span>
              </td>
            </tr>
            <tr>
              <td
                class="w-full text-sm white-space-nowrap overflow-hidden text-overflow-ellipsis"
                data-testid="customer-address"
              >
                <span v-for="(line, index) in props.invoiceAddress.addressLines" :key="line">
                  {{ index > 0 ? ", " : "" }}{{ line }}</span
                >
              </td>
            </tr>
            <tr>
              <td class="w-full text-sm">
                <span data-testid="customer-postal-code" class="mr-1">{{ props.invoiceAddress.postalCode }}</span>
                <span data-testid="customer-postal-city">{{ props.invoiceAddress.city }}</span>
              </td>
            </tr>

            <tr>
              <td class="w-full text-sm" v-if="customer.phoneNumber">
                <span>{{ t(`order.customer.phone`, { symbol: ": " }) }}</span
                ><span data-testid="customer-phone-number">{{ customer.phoneNumber }}</span>
              </td>
            </tr>
            <tr>
              <td class="w-full text-sm" v-if="customer.email">
                <span>{{ t(`order.customer.email`, { symbol: ": " }) }}</span>
                <span data-testid="customer-email">{{ customer.email }}</span>
              </td>
            </tr>
          </table>
        </div>
      </TabPanel>

      <TabPanel :header="t(`order.info-tabs.second-tab`)">
        <table>
          <tr>
            <td class="w-full text-sm" data-testid="delivery-name">{{ props.delivery.customerName }}</td>
          </tr>
          <tr>
            <td class="w-full text-sm" data-testid="delivery-address">
              <span v-for="(line, index) in props.delivery.address.addressLines" :key="line">
                {{ index > 0 ? ", " : "" }}{{ line }}</span
              >
            </td>
          </tr>
          <tr>
            <td class="w-full text-sm">
              <span data-testid="delivery-postal-code" class="mr-1">{{ props.delivery.address.postalCode }}</span>
              <span data-testid="delivery-postal-city">{{ props.delivery.address.city }}</span>
            </td>
          </tr>
          <tr>
            <td class="w-full text-sm" v-if="props.delivery.phoneNumber">
              <span>{{ t(`order.customer.phone`, { symbol: ": " }) }}</span>
              <span data-testid="delivery-phone-number">{{ props.delivery.phoneNumber }}</span>
            </td>
          </tr>
          <tr>
            <td class="w-full text-sm" v-if="props.delivery.email">
              <span>{{ t(`order.customer.email`, { symbol: ": " }) }}</span>
              <span data-testid="delivery-email">{{ props.delivery.email }}</span>
            </td>
          </tr>
        </table>
      </TabPanel>
    </TabView>

    <EditDeliveryInformationDialog
      v-if="showDialog"
      v-model:showDialog="showDialog"
      :currentDelivery="delivery"
      :countries="countryOptions"
      @updateDeliveryInformation="updateDeliveryInformation"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useCountry } from "@/api/country/CountryService";
import { Country } from "@/models/country/Country";
import { Delivery } from "@/models/order/Delivery";
import { OrderStatus } from "@/models/order/OrderStatus";
import { OrderCustomer } from "@/models/order/OrderCustomer";
import { Address } from "@/models/customer/Address";
import type { MenuItem } from "primevue/menuitem";

import EditDeliveryInformationDialog from "../EditDeliveryInformationDialog.vue";

const props = defineProps<{
  delivery: Delivery;
  customer: OrderCustomer;
  invoiceAddress: Address;
  orderStatus?: OrderStatus;
}>();

const { t } = useI18n();

const addressMenu = ref();
const addressMenuOptions = ref([] as MenuItem[]);

const createMenu = () => {
  return [
    {
      label: t(`order.info-tabs.menu-change-delivery-address`),
      icon: "pi pi-pencil",
      command: () => {
        showDialog.value = true;
      },
      dataTestId: "menu-item-change-delivery-address",
      disabled: !allowEdit.value,
      class: !allowEdit.value ? "p-disabled" : "",
    },
  ];
};

const toggleAddressMenu = (event: Event) => {
  addressMenuOptions.value = createMenu();
  addressMenu.value.toggle(event);
};

const emit = defineEmits<{
  (e: "update:delivery", value: Delivery): void;
}>();

const { getAllCountries } = useCountry();

const showDialog = ref<boolean>(false);
const countryOptions = ref<Country[]>([]);

const updateDeliveryInformation = (delivery: Delivery) => {
  emit("update:delivery", delivery);
};

const fetchCountryData = async () => {
  countryOptions.value = await getAllCountries();
};

onMounted(() => {
  fetchCountryData();
});

const allowEdit = computed<boolean>(() => {
  return !props.orderStatus || props.orderStatus === OrderStatus.Open;
});
</script>
<style lang="scss" scoped>
:deep(.c-tabview).p-tabview {
  padding-top: 0.12rem;
}
:deep(.c-tabview).p-tabview .p-tabview-nav {
  border-color: transparent;
}

:deep(.c-tabview).p-tabview .p-tabview-panels {
  padding-bottom: 0;
}

:deep(.c-tabview).p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding: 0.5rem 1rem;
  font-size: 0.85rem;
}

.c-address-menu {
  margin-top: -0.2rem;
  position: relative;
  float: right;
  z-index: 1;
}

.c-description {
  color: var(--text-color-secondary);
}
</style>
