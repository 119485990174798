{
  "validations": {
    "required": "Value is required",
    "email": "Must be a valid e-mail address",
    "minLength": "Minimum length is {min}",
    "exists": "{property} already exists",
    "nonEmptyGuid": "@:validations.required",
    "at-least-one": "At least one {property} must be added",
    "start-number-must-be-greater-than-last-used": "Start number must be greater than last used order number"
  },
  "placeholder": {
    "select": "Select {property}",
    "type": "Type {property}"
  },
  "common": {
    "confirm": "Confirm",
    "total-weight": "Total weight (kg)",
    "total-volume": "Total volume (ccm)",
    "shipping-cost": "Freight cost",
    "add-parcel": "Add parcel",
    "weight": "Weight (kg)",
    "length": "Length (cm)",
    "width": "Width (cm)",
    "height": "Height (cm)",
    "shipping-dialog-header": "Calculate shipping cost",
    "calculate-freight": "Calculate freight",
    "print": "Print",
    "add": "New Order",
    "update": "Update",
    "save": "Save",
    "cancel": "Cancel",
    "search": "Search",
    "clear": "Clear",
    "yes": "Yes",
    "no": "No",
    "delete": "Delete",
    "create": "Create",
    "options": "Options",
    "preview": "Preview",
    "reload": "Reload",
    "column-chooser": "Column chooser",
    "reset": "Reset columns",
    "created": "Created",
    "new-line": "New line",
    "error": "Error",
    "current-page-template": "Showing {first} to {last} of {totalRecords} orders",
    "unsaved-changes-header": "Unsaved changes",
    "unsaved-changes-text": "You have unsaved changes. Save your changes or Discard for leaving without saving your changes. Choose Cancel to continue editing",
    "discard": "Discard",
    "delete-confirm": "Do you want to delete this item?",
    "save-tooltip": "No changes",
    "unit": {
      "pcs": "PCS"
    },
    "error-detail": {
      "unknown": "Unknown error",
      "server-error": "Server error",
      "not-found": "Not found",
      "bad-request": "Bad request",
      "error-occured": "An error occurred"
    }
  },
  "order": {
    "header": "Order",
    "no-result": "No order confirmation found",
    "created-date": "Date",
    "customer-number": "Customer no.",
    "customer-name": "Customer name",
    "freight-method-name": "Freight method",
    "quantity-of-order-items": "Order lines",
    "sum-of-total-ex-vat": "Total ex. VAT",
    "pdf": "PDF",
    "printers": "Printers",
    "print-success": "Order confirmation sent to printer successfully",
    "print-success-detail": "Order confirmation is being printed to {printerName}",
    "printix": "Printix",
    "setup-printix-message": "Printix isnt setup yet. Go here to setup {0}",
    "add-order": "Add Order",
    "edit-order": "Edit Order",
    "search-order": "Order Search",
    "created": "Registered",
    "by": "By",
    "empty-list": "Use search to add products",
    "product-search": "Search for products",
    "no-products-found": "No products found",
    "search-input-is-empty": "Search input is empty, write something to search...",
    "order-line": "Order line",
    "download-link-missing": "Download link is missing",
    "discount-error-summary": "Could not get discount information",
    "discount-error-detail": "An error occured while getting discount information.",
    "view-order": "View order",
    "search-mode": "Search mode",
    "hide-panel": "Hide panel",
    "show-panel": "Show panel",
    "context-menu": {
      "edit": "Edit",
      "delete": "Delete",
      "product-info": "Product Info"
    },
    "order-history": {
      "title": "Order history",
      "confirmation-line": "Order confirmation",
      "picking-list-line": "Picking list",
      "packing-note-line": "Packing note",
      "invoice-line": "Invoice",
      "credit-note-line": "Credit note",
      "shipment-number": "Shipping number"
    },
    "properties": {
      "position-number": "PosNr.",
      "main-image": "Image",
      "product-number": "Product number",
      "product-name": "Product name",
      "quantity": "Quantity",
      "for-delivery": "For delivery",
      "back-order": "Back order",
      "back-order-date": "Back order date",
      "delivery-date": "Delivery date",
      "sales-unit": "Unit",
      "price-excl-vat": "Price",
      "price-incl-vat": "Price inc.vat",
      "cost-price": "Cost",
      "contribution-margin": "CM%",
      "sum-excl-vat": "Sum excl.vat",
      "in-stock": "Availability",
      "discounted-product-percentage": "Discount %",
      "discounted-product-price-ex-vat": "Price ex.vat",
      "alternatives": "Alt",
      "accessory": "Acc"
    },
    "search-filters": {
      "order-status": "Order status",
      "columns": "Columns"
    },
    "customer": {
      "no-customer": "No customer selected",
      "header-business": "Business",
      "label": "Customer",
      "select": "Search customer",
      "no-search-result": "'{search}' not found",
      "empty-search-query": "Please search for a customer",
      "loading": "Loading customer, please wait...",
      "search-footer-result": "Showing {first} to {last} of {totalRecords} customers",
      "number": "Customer number{symbol}",
      "name": "Name",
      "city": "City",
      "email": "E-mail{symbol}",
      "delivery-to": "Delivery to:",
      "phone": "Phone{symbol}"
    },
    "product": {
      "accessories": "Accessories",
      "product": "Product",
      "alternatives": "Alternatives",
      "open-product": "Open product",
      "gtin": "GTIN(EAN):",
      "group-level": "Group level:",
      "dimension-and-weight": "Dimension & weight:",
      "manufacturer": "Manufacturer",
      "description": "Description",
      "name": "Product name",
      "number": "Product number",
      "type": "Product type",
      "unit": "Unit",
      "dimensions": {
        "label" : "Dimensions",
        "height": "Height",
        "width": "Width",
        "length": "Length",
        "weight": "Weight"
      },
      "info-dialog-header": "Product Info",
      "no-image": "No image found",
      "more-info": "More Information"
    },
    "order-reference": {
      "label": "Order reference",
      "placeholder": "Order reference"
    },
    "contact": {
      "label": "Contact person",
      "search-placeholder": "Search for contact person or write name of a custom contact",
      "no-contacts-found": "No contacts found. Write name and click 'Enter' for custom contact.",
      "empty-search-query": "Please search for a contact person or write name of a custom contact",
      "firstname": "First name",
      "lastname": "Last name",
      "phone": "Phone",
      "email": "Email",
      "default-contact": "Default contact",
      "email-label": "E-mail",
      "is-required": "Firstname, lastname or e-mail is required",
      "invalid-email": "Invalid email"
    },
    "payment-term": "Payment term",
    "freight-method": "Freight method",
    "delivery-option": {
      "label": "Delivery",
      "total": "Total delivery"
    },
    "comment": {
      "label": "Comment",
      "placeholder": "Add a comment.."
    },
    "add": {
      "title": "Create order",
      "toast": {
        "success": {
          "summary": "Create order",
          "detail": "Order {orderNumber} is now saved"
        },
        "validation": {
          "summary": "Validation failed",
          "detail": "All red fields must be corrected"
        }
      }
    },
    "edit": {
      "title": "Order infomation",
      "toast": {
        "success": {
          "summary": "Order was updated",
          "detail": "Changes are soon ready"
        }
      }
    },
    "delete": {
      "confirm-message": "Do you want to delete order?",
      "toast": {
        "success": {
          "summary": "Deleting order",
          "detail": "Order is soon deleted"
        }
      }
    },
    "toast-add-product-summary": "{quantity} product(s) was added",
    "order-number": {
      "label": "Order no.",
      "assigned": "Gets assigned when its created"
    },
    "status": {
      "open": "Open",
      "delivered": "Shipped",
      "partiallydelivered": "Partially delivered",
      "picking": "Picking",
      "processing": "Processing"
    },
    "search": {
      "registered": "Registered",
      "order-number": "Order number",
      "order-reference": "Order reference",
      "customer-number": "Customer no.",
      "customer-name": "Customer",
      "order-status": "Status",
      "total-hits": "No orders found | 1 order found | {totalHits} orders found",
      "empty-list": "No orders found.",
      "toast": {
        "error": {
          "summary": "Failed to load orders"
        },
        "open-order-failed": "Failed to open order",
        "no-order-selected": "No order selected. Select a order and try again."
      },
      "filter": "Filter",
      "filter-order-date": "Order date",
      "filter-date-from": "From{symbol}",
      "filter-date-to": "To{symbol}",
      "filter-last-ten-days": "Last 10 days",
      "filter-status": "Status",
      "filter-customer-number": "Customer number",
      "filter-customer-reference": "Customer reference",
      "filter-product-number": "Product number",
      "show-filter": "Show filter",
      "hide-filter": "Hide filter",
      "clear-filter": "Clear filter"
    },
    "footer": {
      "discount": "Discount:",
      "margin": "Margin:",
      "sum-ex-vat": "Sum items ex. VAT:",
      "sum-inc-vat": "Sum items inc. VAT:",
      "freight": "Freight(agreed):",
      "freight-inc-vat": "Freight inc. VAT:",
      "total": "Order total inc. VAT:",
      "vat": "VAT:",
      "orderline": "Order lines"
    },
    "address": {
      "dialog-header": "Change delivery information",
      "customer-name": "Customer name",
      "email": "Email",
      "phone-number": "Phone number",
      "lines": "Address",
      "country": "Country",
      "city": "City",
      "postal-code": "Postal code",
      "postal-code-city": "Postal code / City"
    },
    "complementary-items": {
      "accessories": "Accessories",
      "alternatives": "Alternatives",
      "header": {
        "available": "Available",
        "cost-price": "Cost price",
        "contribution-margin": "CM%",
        "price-excl-vat": "Price ex. vat",
        "price-incl-vat": "Price inc. vat"
      },
      "buttons": {
        "add": "Add"
      }
    },
    "availability-title": "Availability",
    "info-tabs": {
      "first-tab": "Customer",
      "second-tab": "Delivery to",
      "menu-change-delivery-address": "Change delivery address"
    },
    "shipping-price": "Shipping price",
    "shipping-price-empty": "Set fixed shipping price"

  },
  "product-warehouse-availability": {
    "warehouse-name": "Warehouse",
    "quantity-in-stock": "Available",
    "quantity-available-from-stock": "Avail. inventory",
    "quantity-in-orders": "Order reserve",
    "select-product": "Please select a product",
    "not-found": "No warehouse availability found for this product"
  },
  "product-purchase-quantity": {
    "purchase-order-no": "Purchase no.",
    "quantity": "Quantity",
    "open": "Open",
    "cost-price": "Cost price",
    "eta-date": "ETA",
    "select-product": "Please select a product",
    "not-found": "No open purchase orders found for this product"
  },
  "product-supplier-price": {
    "title": "Suppliers",
    "supplier-name": "Supplier",
    "supplier-product-no": "Supplier product no",
    "product-cost-price": "Cost price",
    "select-product": "Please select a product",
    "not-found": "No supplier price found for this product"
  },
  "settings": {
    "order-settings": "Order Settings",
    "order-number-series": "Order number series",
    "current-setup": "Current order number series for this company",
    "last-used-order-number": "Last used order number",
    "currently-used-start-number": "Currently used start number",
    "set-new-start-number": "Set new start number",
    "set-new-start-number-success": "New start number set successfully",
    "no-order-number-found": "No order number series is setup for this company"
  }
}
