<template>
  <div class="mt-3">
    <FloatLabelDropdownPanel
      ref="commentInputRef"
      id="comment"
      :selectedItemName="comment?.trim() ?? ''"
      :label="t('order.comment.label')"
      :selectLabel="t('placeholder.type', { property: t('order.comment.label').toLowerCase() })"
      @toggleDropdownPanel="showCommentPanel"
      maxlength="15000"
      dataTestId="order-comment"
      class="c-comment"
      :class="{ 'p-disabled': !allowEdit }"
      :disabled="!allowEdit"
    />

    <CommentPanel ref="commentPanelRef" v-model:comment="comment" @tabKeyPressed="tabKeyPressed" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { OrderStatus } from "@/models/order/OrderStatus";
import CommentPanel from "./CommentPanel.vue";

const { t } = useI18n();

const props = defineProps<{
  orderStatus?: OrderStatus;
}>();

const comment = defineModel<string>("comment");

const allowEdit = computed<boolean>(() => {
  return (
    !props.orderStatus || props.orderStatus === OrderStatus.Open || props.orderStatus === OrderStatus.PartiallyDelivered
  );
});

const commentPanelRef = ref();
const showCommentPanel = (event: Event) => {
  const key = (event as KeyboardEvent).key;
  if (key === "Shift" || key === "Escape" || key === "Tab") {
    return;
  }

  commentPanelRef.value.toggle(event);
};
const commentInputRef = ref();
const tabKeyPressed = () => commentInputRef.value.$el?.querySelector("button")?.focus();
</script>
<style lang="scss" scoped>
:deep(.c-comment) .p-dropdown-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 10rem;
}
</style>
