import { useErrorHandler } from "@/api/ErrorHandler";
import { useAuth } from "@cumulus/event-bus";
import { documentApi } from "./DocumentApi";
import { PrintDocumentRequest } from "@/models/print/PrintDocumentRequest";
import { DocumentType } from "@/models/print/DocumentType";
import { NotFoundError } from "@cumulus/http";

export function useDocument() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getOrderConfirmationUrl = async (orderId: string): Promise<string> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await documentApi.getOrderConfirmationUrl(authHeaders, orderId);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getPackigNoteUrl = async (packingNoteId: string): Promise<string> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await documentApi.getPackingNoteUrl(authHeaders, packingNoteId);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getInvoiceUrl = async (invoiceId: string): Promise<string> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await documentApi.getInvoiceUrl(authHeaders, invoiceId);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getCreditNoteUrl = async (creditNoteId: string): Promise<string> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await documentApi.getCreditNoteUrl(authHeaders, creditNoteId);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getAllPrinters = async () => {
    try {
      const authHeaders = await getAuthHeaders();
      return await documentApi.getPrinters(authHeaders);
    } catch (error) {
      handleError(error);
      throw error;
    }
  };

  const getDefaultPrinterByDocumentType = async (documentType: DocumentType) => {
    try {
      const authHeaders = await getAuthHeaders();
      return await documentApi.getDefaultPrinterByDocumentType(authHeaders, documentType);
    } catch (error) {
      if (!(error instanceof NotFoundError)) {
        console.error(error);
      }
    }
  };

  const printDocument = async (request: PrintDocumentRequest) => {
    try {
      const authHeaders = await getAuthHeaders();
      return await documentApi.printDocument(authHeaders, request);
    } catch (error) {
      handleError(error);
      throw error;
    }
  };
  return {
    getOrderConfirmationUrl,
    getPackigNoteUrl,
    getInvoiceUrl,
    getCreditNoteUrl,
    getAllPrinters,
    getDefaultPrinterByDocumentType,
    printDocument,
  };
}
