<template>
  <div class="c-chips">
    <ul class="c-chips-multiple-container" v-if="hasActiveFilters">
      <li class="c-chips-token-clear-filter">
        <span>{{ t("order.search.clear-filter") }}</span>
        <span class="c-chips-token-icon pi pi-times-circle" @click="clearFilters" data-testid="clear-filter-btn">
        </span>
      </li>
      <FilterItem
        v-if="filters.fromDate !== null"
        filterType="fromDate"
        :values="d(filters.fromDate)"
        @removeFilter="removeFilter"
      />
      <FilterItem
        v-if="filters.toDate !== null"
        filterType="toDate"
        :values="d(filters.toDate)"
        @removeFilter="removeFilter"
      />
      <FilterItem
        v-if="filters.orderStatuses.length > 0"
        :values="filters.orderStatuses.map((status) => t(`order.status.${status.toLowerCase()}`))"
        filterType="orderStatuses"
        @removeFilter="removeFilter"
      />
      <FilterItem
        v-if="filters.productNumbers.length > 0"
        :values="filters.productNumbers"
        filterType="productNumbers"
        @removeFilter="removeFilter"
      />
      <FilterItem
        v-if="filters.productNames.length > 0"
        :values="filters.productNames"
        filterType="productNames"
        @removeFilter="removeFilter"
      />
      <FilterItem
        v-if="filters.customerNumbers.length > 0"
        :values="filters.customerNumbers"
        filterType="customerNumbers"
        @removeFilter="removeFilter"
      />
      <FilterItem
        v-if="filters.customerNames.length > 0"
        :values="filters.customerNames"
        filterType="customerNames"
        @removeFilter="removeFilter"
      />
      <FilterItem
        v-if="filters.orderReferences.length > 0"
        :values="filters.orderReferences"
        filterType="orderReferences"
        @removeFilter="removeFilter"
      />
    </ul>
  </div>
</template>

<script setup lang="ts">
import { OrderSearchFilters } from "@/models/search/order/OrderSearchFilters";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import FilterItem from "./FilterItem.vue";

const { t, d } = useI18n();

const props = defineProps<{
  filters: OrderSearchFilters;
}>();

const emit = defineEmits<{
  (event: "update:filters", value: OrderSearchFilters): void;
}>();

const clearFilters = () => {
  const filters = new OrderSearchFilters();
  emit("update:filters", filters);
};

const removeFilter = (filterType: keyof OrderSearchFilters, index: number) => {
  const filters = { ...props.filters };
  const filterToRemove = filters[filterType];

  if (Array.isArray(filterToRemove)) {
    filterToRemove.splice(index, 1);
  } else if (filterType === "fromDate" || filterType === "toDate") {
    filters[filterType] = null;
  }

  emit("update:filters", filters);
};

const hasActiveFilters = computed<boolean>(() => {
  return (
    props.filters.orderStatuses.length > 0 ||
    props.filters.productNumbers.length > 0 ||
    props.filters.productNames.length > 0 ||
    props.filters.customerNumbers.length > 0 ||
    props.filters.customerNames.length > 0 ||
    props.filters.orderReferences.length > 0 ||
    props.filters.fromDate !== null ||
    props.filters.toDate !== null
  );
});
</script>
<style scoped lang="scss">
.c-chips {
  display: inline-flex;
}

.c-chips-multiple-container {
  padding: 0.25rem 0.5rem;
  margin: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.c-chips-token-clear-filter {
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  color: var(--primary-color-text);
  border-radius: 1rem;
  background-color: var(--chips-bg-primary);
  display: inline-flex;
  align-items: center;
}

.c-chips-token-icon {
  margin-left: 0.5rem;
  cursor: pointer;
}
</style>
